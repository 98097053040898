@import "../common/assets/styles/config.scss";
@import "../common/assets/styles/theme/main.css";
@import "../common/assets/styles/theme/responsive.css";
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

body, .dx-widget,.dx-field input, .dx-field textarea{
  font-family: 'Questrial', sans-serif !important;
}
.rounded-circle-avatar {
  width: 40px;
  padding-right: 5px;
}
.dx-datagrid-header-panel .dx-toolbar{
  background: none
}
.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child{
  max-width: none !important;
 }
.w-20{
  width: 19.35%;
  padding-right: 15px;
}
.w-80{
  width: 80.65%;
}
.DescriptionBox .dx-field-label{
  width: 12%;
  padding-right: 15px;
}
.DescriptionBox .dx-field-value{
  width: 88% !important;
}
img{max-width: 100%;}
.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child{
  max-width: none !important;
 }
 .form a {
     cursor: pointer;
     white-space: nowrap;
     text-decoration: none;
 }
 
 .form p {
     margin: 0;
 }
 .login-container .dx-texteditor-buttons-container > .dx-button {
  margin: 0;
  border-width: 3px;
}
.dx-drawer-content{
  height: calc(100% - 50px);
  overflow: auto;
}
.tbl_fontSize{
  font-family: 'Times New Roman', sans-serif;
}
.tbl_fontSize p{
}
// .main-header-top {
//   background: transparent !important;
//   .navbar {
//     background: $background-menu !important;
//   }
// }

// .main-sidebar {
//   background: $background-menu !important;
// }
.sidebar-menu > li > a{
  color: #fff;
}

.sidebar-menu > li > a svg {
  color: #fff !important;
}

aside.main-sidebar {
  overflow: unset;
}
.sidebar-menu {
  overflow: unset !important;
  li {
    a {
      color: white;
    }
  }

  .treeview-menu {
    margin: 1px 0;
    overflow: unset;
    li {
      .treeview {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
      }
    }
  }
  .treeview .ellipsis-txt {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    position: relative;
    top: 5px;
  }

  li.nav-item .treeview span.tooltips {
    display: none;
    position: absolute;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #000 !important;
    bottom: -15px;
    left: 10px;
    font-size: 14px;
    z-index: 9999;
  }

  li.nav-item .treeview span.tooltips::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    z-index: 9999;
  }

  li.nav-item .treeview.ellipsis-txt:hover > span.tooltips {
    color: #fff;
    white-space: nowrap;
    background-color: #000 !important;
    display: inline-block;
  }

}
// .dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child{
//   text-align: center !important;
// }
// .sub-menu-header.active > a {
//   background: $item-active !important;
// }

// .sidebar-menu .treeview-menu > li.active > a {
//   color: #16bdd8;
//   font-weight: bold;
// }


// .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu, .sidebar-mini.sidebar-collapse .sidebar-menu > li.active > .treeview-menu {
//   background: $background-menu;
// }

// .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right), .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span > .treeview-menu, .sidebar-mini.sidebar-collapse .sidebar-menu > li.active > a > span:not(.pull-right), .sidebar-mini.sidebar-collapse .sidebar-menu > li.active > a > span > .treeview-menu {
//   background: $background-menu;
//   color: white;
// }

#root {
  width: 100%;
  height: 100%;
  position: fixed;
}

#root > div {
  width: 100%;
  height: 100%;

  main {
    width: 100%;
    height: auto;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
a:hover,  a:focus {
    color: inherit !important;
    opacity: .9 !important;
    text-decoration: none !important; 
  }
  
.wrap-content {
  width: 100%;
  height:100% ;
}
.main-header-top{
  background-color: rgba(191, 191, 191, .15);
}
.main-header-top .top-nav .settings-menu li a svg{
  width: 50px;
}
.nav-tabs .active{
  border-top: 1px solid !important;
}
.content-wrapper {
  // height:calc(100% - 50px) ;
  // overflow: auto;

  // .container-fluid {
  //   height: 100% !important;

  //   .dashboard {
  //     height: 100% !important;
  //   }
  // }
}
// .dx-button{
//   height: 36px;
// }
.dx-button-text{
  text-transform: inherit !important;
}
.card-tb-body{
  padding: 1.5rem;
}
.card-tb{
  margin-bottom: 30px;
}

.dx-gallery{
  max-width: 100% !important;
}
// .tab-info{
//   position: fixed;
//   max-width: calc(25% - 30px) ;
// }
.tab-info{
  margin-top: 60px;
}
.wrap-avatar{
  height:150px;
  width:150px;
  margin:auto;
  border:1px solid #d2d3d5;
  border-radius:50%;
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center
}
.dx-texteditor.dx-state-readonly{
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-width: 1px;
}
.tb-custom td{
  padding: 5px;
}
.tb-custom td i{
  position: relative;
  top: 2px;
}
td .d-flex.align-items-center span{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
td .d-flex.align-items-center img{
  object-fit: cover;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}
// .dashboard .dx-widget{
//   min-width: 100% !important;
// }
.dx-widget.dx-visibility-change-handler.dx-treelist{
  min-width: 100% !important;
}
#sidebar-scroll{
  overflow-y: auto !important;
  height: 100vh;
}
.dx-scrollbar-horizontal.dx-scrollbar-hoverable{
  height: 5px;
}
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover, .dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
  height: 8px;
}
.dx-popup-content .tab-lang-style{
  position: fixed;
  top: 63px;
  left: 35px;
  width: calc(100% - 70px);
  z-index: 9;
}
.dx-popup-content .p-tab-lang{
  padding-top: 60px;
}
.action-noti .dropdown-item{
  color: inherit;
  cursor: pointer;
}
.action-noti .dropdown-item:hover{
  color: initial;
}
