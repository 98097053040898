.dx-texteditor.dx-editor-underlined .dx-texteditor-input{
    padding: 17px 10px;
}
.form-avatar {
    height: 90px;
    max-width: 90px;
    width:  auto;
    margin-right: 10px;
    border: 1px solid #d2d3d5;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.title-changepass{
    line-height: 90px;   
}
h2 {
    font-size: 23px ;
}
input:-internal-autofill-selected {
 background: transparent
}
.btn-default >div{
    background: #d2d3d5;
    color: #000;
}