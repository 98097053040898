$default-color: #222;
$font-family: "Arial", tahoma, Helvetica Neue;
$color-blue: #3598dc;
$main-color: #007455;
$borderColor: #ddd;
$second-color: #b01a1f;
$table-background-color: #009688;
$blue: #007bff;
$background-menu: #4276A4;
$dark-blue: #0072BC;
$bright-blue: #dff0fd;
$indigo: #6610f2;
$purple: #6f42c1;
$item-active: #d64635;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #868e96;
$gray-dark: #343a40;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$label-color: #666;
$background-color: #ECF0F1;
$borderActiveColor: #80bdff;
$borderRadius: 0;
$darkBlue: #45A2D2;
$lightGreen: #27ae60;
$light-blue: #f5f7f7;
$brightGray: #757575;
$max-width-mobile: 768px;
$max-width-tablet: 992px;
$max-width-desktop: 1280px;

// BEGIN: Margin
@mixin nh-mg($pixel) {
    margin: $pixel !important;
}

@mixin nh-mgt($pixel) {
    margin-top: $pixel !important;
}

@mixin nh-mgb($pixel) {
    margin-bottom: $pixel !important;
}

@mixin nh-mgl($pixel) {
    margin-left: $pixel !important;
}

@mixin nh-mgr($pixel) {
    margin-right: $pixel !important;
}

// END: Margin

// BEGIN: Padding
@mixin nh-pd($pixel) {
    padding: $pixel !important;
}

@mixin nh-pdt($pixel) {
    padding-top: $pixel !important;
}

@mixin nh-pdb($pixel) {
    padding-bottom: $pixel !important;
}

@mixin nh-pdl($pixel) {
    padding-left: $pixel !important;
}

@mixin nh-pdr($pixel) {
    padding-right: $pixel !important;
}

// END: Padding

// BEGIN: Width
@mixin nh-width($width) {
    width: $width !important;
    min-width: $width !important;
    max-width: $width !important;
}

// END: Width

// BEGIN: Icon Size
@mixin nh-size-icon($size) {
    width: $size;
    height: $size;
    font-size: $size;
}

// END: Icon Size
