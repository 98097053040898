@import "./common/assets/styles/config.scss";
@import "./common/assets/styles/_common.scss";
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
body {
  font-family: "Questrial", sans-serif;
}
.page-title {
  margin-left: -10px !important;
  padding: 10px 0px;
  font-size: 28px;
  letter-spacing: -1px;
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  span {
    padding-left: 10px !important;
    font-weight: bold;
  }
  /* subtitle */
}
.customer-scheduler {
  height: 650px;
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.page-a4 {
  padding: 1.5cm 0cm 0;
  width: 21cm;
  min-height: 29.7cm;
  background: white;
  display: block;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}
.page-a4 .dx-field {
  font-size: 14px;
  font-family: "Times New Roman", Times, serif;
}
.page-a4 .dx-field-label {
  color: #000;
  text-overflow: initial;
  height: 50px;
  display: flex;
  align-items: center;
  word-break: break-all;
  font-family: "Times New Roman", Times, serif;
}
.page-a4.print-user .dx-field-label {
  width: 150px;
}
.page-a4 .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
.dx-field-value-static {
  width: 60%;
}
.page-a4 .w-20 {
  color: #000;
  text-overflow: initial;
  height: 50px;
  display: flex;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
}
.page-a4 .text {
  color: #000;
}
.demo-print {
  border-bottom: 1px dashed #595656;
  height: 50px;
  display: flex;
  align-items: center;
}
.table-bill {
  margin-bottom: 20px;
  width: 100%;
}
.table-bill td {
  padding: 7px 0px 7px 0;
}
.table-goodNote td {
  padding: 2px 0 !important;
  // border-bottom: 1px dotted;
}
.table-bill.table-goodNote {
  margin-bottom: 5px;
}
.table-goodNote td:first-child {
  padding-right: 30px;
  width: 25%;
  // border-bottom: 0px solid;
}

.table-inventory td,
.table-inventory th {
  height: 40px;
  padding: 2px;
  border: 1px solid;
  text-align: center;
}

// .table-inventory .info-product:nth-child(n),
// .table-inventory .total-inventory {
//   text-align: center;
// }
// .signature .col:first-child{
//   text-align: left;
// }
// .signature .col:last-child{
//   text-align: right;
// }
// .table-inventory th {
//   text-align: center;
// }
.branch-note p {
  margin-bottom: 0;
}

.table-service-bill {
  font-size: 0.9em;
  width: 100%;
}
.table-service-bill td,
.table-service-bill th {
  padding: 5px;
  border: 1px solid;
}

.table-service-bill thead {
  white-space: nowrap;
}
.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
}

// Lịch sử thanh toán
.bill-history {
  padding: 10px;

  .top-content {
    margin-bottom: 30px;

    .dx-field {
      display: flex;

      .dx-field-label {
        flex-basis: 130px;
        width: 130px !important;
      }

      .dx-field-value {
        flex-grow: 1;
      }
    }
  }

  .bottom-line {
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
  }
  .body-title {
    padding-left: 30px;
    margin-bottom: 20px;
    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .body-content {
    padding-left: 30px;
    font-size: 1rem !important;
  }

  .demo-print {
    border-bottom: 1px dashed #595656;
    height: 50px;
    display: flex;
    align-items: center;
  }
}

// datagrid header
.fixed-header-datagrid
  .dx-datagrid-headers
  .dx-datagrid-table
  .dx-header-row
  td {
  white-space: nowrap;
}
// .service-list {
//   .dx-datagrid-headers .dx-datagrid-table .dx-header-row td {
//     white-space: nowrap;
//   }
// }
.dx-context-menu.dx-menu-base.no-render li:first-child,
.dx-context-menu.dx-menu-base.no-render li:last-child {
  display: none;
}

.dx-context-menu.dx-menu-base.to-parent-folder li:not(:nth-child(5)) {
  display: none;
}
// .dx-context-menu.dx-menu-base.no-render li:first-child,
// .dx-context-menu.dx-menu-base.no-render li:last-child,
// .dx-filemanager-context-menu.dx-context-menu.dx-menu-base.no-render li:first-child,
// .dx-filemanager-context-menu.dx-context-menu.dx-menu-base.no-render li:last-child {
//   display: none;
// }

.dx-filemanager-context-menu.dx-context-menu.dx-menu-base.no-render
  li:not(:nth-child(5)),
.dx-filemanager-context-menu.dx-context-menu.dx-menu-base.no-render.dx-state-invisible
  li:not(:nth-child(5)) {
  display: none;
}

#sidebar-scroll::-webkit-scrollbar {
  display: none;
}
.dx-treeview .dx-empty-message {
  margin-top: 10px;
}
.show-rights-group-permissions .item-roles {
  width: 50%;
  float: left;
}
.has-error {
  .dx-texteditor.dx-editor-outlined {
    border: 1px solid red;
  }
  .dx-htmleditor {
    border: 1px solid red;
    input {
      border: 0px;
    }
  }
  .help-block {
    color: red !important;
  }
}
.modal-dialog-resize {
  max-width: 80%;
  &.sm {
    width: 25%;
  }
}
.toast-container {
  font-size: 18px !important;
}
.font-red-sunglo {
  color: $red !important;
}
.uppercase {
  text-transform: uppercase !important;
}

.wrap-core {
  width: 100%;
  .menu-core {
    border: 1px solid gray;
    padding: 0px !important;
    li {
      &:hover {
        background: $white;
        a {
          color: black;
        }
      }
      text-align: center;
      border-bottom: 1px solid gray;
      padding: 10px 0px;
      a {
        text-decoration: none;
        color: $gray;
      }
    }
  }
}
.has-language-error {
  color: $red;
}
.active-left {
  float: left !important;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.caption-subject {
  color: $red;
}
.wrap-main-form {
  background: #ffffff;
  -webkit-box-shadow: 7px 32px 92px -30px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 32px 92px -30px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 32px 92px -30px rgba(0, 0, 0, 0.75);
}

.portlet {
  &.bordered {
    border: 1px solid #e7ecf1 !important;
  }
}

.card-body {
  padding: 0px !important;
}

#account-treeview-config {
  height: auto;
}

.dx-state-disabled {
  color: #cccccc !important;
}
.btn-acc {
  position: relative;
  margin-top: 8px;
  top: -1px;
}

.btn-acc .dx-buttongroup-wrapper {
  width: 100%;
}
.btn-acc .dx-button-text {
  font-size: 14px;
  font-weight: normal;
  // line-height: 1.2857;
}
.btn-acc img {
  height: 30px !important;
  object-fit: cover;
  width: 30px !important;
}
.btn-acc .dx-button-has-icon .dx-button-content {
  min-width: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  line-height: 35px;
}
.btn-acc .dx-button .dx-icon {
  position: relative;
  top: -5px;
  line-height: 24px;
}
.btn-acc .dx-button img.dx-icon {
  position: relative;
  top: 0px;
}
.btn-acc .dx-buttongroup-wrapper {
  display: inline-block;
}
.btn-acc .dx-button {
  height: 39px;
  width: calc(100% - 26px);
}
.dx-wrap-item-text > .dx-list-item > .dx-list-item-content {
  font-size: 13px;
}
.set-focus-tab {
  border-bottom: 1.5px solid transparent;
}

.dx-theme-background-color action_form {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
.notification-menu .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 10px 12px 10px;
  font-size: 14px;
}
@media (max-width: 1025px) {
  .header-fixed .main-header-top .dx-texteditor {
    max-width: 180px;
  }
}
.dx-popup-content 
/* custom theme */
.dx-field {
  margin: 0 0 7px 0 !important;
}
.dx-field .wrapper-button-center {
  text-align: center;
  margin-bottom: 15px;
}
.row .wrapper-button-center-service {
  display: flex;
  justify-content: center;
  // justify-content: space-around;
}
.dx-dialog .dx-toolbar-center {
  margin: 0 0 0 auto !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td,
.dx-treelist-content .dx-treelist-table .dx-row > td,
.dx-treelist-content .dx-treelist-table .dx-row > tr > td {
  vertical-align: middle;
  // white-space: initial;
}

.dx-list-item-icon-container {
  width: 30px;
}
.dx-field-label {
  width: 25%;
  white-space: inherit;
}

.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
.dx-field-value-static {
  width: 75%;
}
// .dx-scrollbar-hoverable{
//   display: none;
// }
.user_custom_form .dx-theme-background-color action_form {
  position: relative;
}
.custom_select_warehouse .dx-field-label {
  display: none;
}
.custom_select_warehouse .dx-texteditor {
  width: 300px;
}
.custom_select_warehouse .dx-field {
  display: flex;
}
.branch_custom .dx-toolbar .dx-texteditor {
  width: 100% !important;
}

.branch_custom .dx-toolbar .dx-toolbar-after {
  padding-left: 0px;
  width: 100% !important;
}
.branch_custom .dx-datagrid-header-panel {
  padding: 0;
}
.branch_custom .dx-datagrid-search-panel {
  margin-left: 0px;
}
.branch_custom
  .dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-command-select {
  padding: 0;
}

// .tab-info .dx-tab-content{
//   float: left;
// }
.tab-info .dx-tab .dx-icon {
  margin: 0 10px;
  display: inline-block;
}
.tab-info .dx-tab-text {
  margin: 0 10px;
}
.loading-noti .m-loader {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 11px);
}
.unread {
  display: none;
}
.tab-noti {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}
.is-read .unread {
  display: block;
  position: absolute;
  right: 30px;
  top: 22px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.main-header-top .top-nav .notification-menu .is-read .media .media-body {
  opacity: 1;
  font-weight: bold;
}
.main-header-top .top-nav .notification-menu .media .media-body {
  opacity: 0.8;
}
.prepaid-card {
  .dx-field {
    display: flex;
    flex-wrap: nowrap;
    .dx-field-label {
      min-width: 200px;
      flex-basis: 200px;
    }
  }
}

.invoice-info {
  .dx-field {
    display: flex;
    flex-wrap: nowrap;
    .dx-field-label {
      min-width: 130px;
      flex-basis: 100px;
    }
  }
}
.invoice-info-detail {
  .dx-field {
    display: flex;
    flex-wrap: nowrap;
    .dx-field-label {
      min-width: 200px;
      flex-basis: 100px;
    }
  }
}
// input{
//   padding: 5px 9px 4px !important;
//   height: 35px  !important;
//   line-height: 35px  !important;
// }
// .dx-placeholder:before{
//   padding: 5px 9px 6px !important;
// }
// .dx-tab .dx-icon{
//   display: inline-block;
// }
// .dx-item.dx-radiobutton{
//   white-space: nowrap;
// }
// .dx-button{
//   height: 35px;
// }

//  .dx-button-text{
//   padding: 5px 9px 6px !important;
//   line-height: 33px  !important;
//   font-weight: normal;
// }
// .dx-button .dx-button-content, .dx-button-has-icon.dx-button-has-text .dx-button-content {
//   padding: 0px 0px !important;
//   line-height: 20px;
// }
// .dx-button-has-icon .dx-button-content{
//   line-height: 30px !important;
//   padding: 0px !important;
//   min-width: 35px;
// }
// .dx-dropdownbutton-toggle.dx-button .dx-button-content{
//   line-height: 33px  !important;
// }
// .dx-datagrid .dx-row > td, .dx-treelist .dx-column-lines > td{
//   padding: 9px !important;
// }
// .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell, .dx-row.dx-column-lines.dx-datagrid-filter-row td[role="gridcell"]{
//   padding: 0px 5px !important;
// }
// .dx-texteditor-input, .dx-placeholder{
//   font-size: 14px;
// }
// .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu{
//   height: 40px;
// }
// .dx-editor-outlined .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button:last-child, .dx-editor-filled .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button:last-child{
//   margin-right: 0;
// }
// .dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content, .dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
//   padding: 6px 4px;
// }

// .dx-wrap-item-text > .dx-list-item > .dx-list-item-content, .dx-list-item-content{
//   padding: 7px 5px !important;
//   font-size: 13px;
// }
// .dx-list-item-icon {
//   font-size: 19px;
//   line-height: 31px;
// }

// .dx-searchbox .dx-icon-search{
//   right: 0;
// }
// .dx-treeview-search .dx-placeholder{
//   left: 20px;
// }
// .dx-treeview-search.dx-searchbox.dx-editor-filled .dx-icon-search, .dx-treeview-search.dx-searchbox.dx-editor-outlined .dx-icon-search{
//   left: auto;
// }
// .dx-field-label{
//   text-align: right;
// }
// .dx-tabs-nav-button{
//   display: none;
// }
.wrap_selectBatches > div {
  width: 85%;
  display: inline-block;
}
.wrap_selectBatches .buttonBatches {
  display: inline;
}
.action-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dx-icon-remove {
  color: red;
}

.text-in-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
@page {
  size: auto;
  text-align: center;
}
.table-bill {
  max-width: 100%;
}
.tbl_fontSize p,
.tbl_fontSize th,
.tbl_fontSize td {
  font-size: 19px;
  color: #000;
  margin-bottom: 8px;
}
.tbl_fontSize td {
  // height:40px;
}
.tbl_fontSize_small td {
  font-size: 16px;
  color: #000;
}

.bill-detail .text-in-cell {
  overflow: unset;
  text-overflow: unset;
  display: -webkit-box;
  // word-break: normal;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.invoice-info .field-title {
  // min-width: 100px;
  // max-width: 145px;
  width: 150px;
  margin-right: 5px;
}

.custom-tabs .dx-tabs-wrapper {
  display: flex;
  .dx-tab {
    flex-grow: 1;
  }
}
.info-service .tab-lang-style {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.info-service .p-tab-lang {
  padding-top: 0px;
}
.side-navigation-menu {
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.side-navigation-menu .dx-treeview {
  .dx-treeview-node-container {
    .dx-treeview-node {
      &.dx-state-selected > .dx-treeview-item * {
        color: red;
      }
    }
  }
}
// .side-navigation-menu .dx-treeview-item {
//   padding: 0;
// }
// .side-navigation-menu .dx-treeview-node::before {
//   position: absolute;
//   content: "";
//   display: block;
//   height: 1px;
//   bottom: 0;
//   background: rgba(221, 221, 221, 0.2);
//   width: calc(100% + 30px);
//   right: 0;
//   z-index: 999999;
// }
// .side-navigation-menu .dx-treeview-item .dx-treeview-item-content a {
//   color: #fff;
//   padding: 13px 6px;
//   font-size: 14px;
//   display: block;
// }
// .side-navigation-menu .dx-treeview-node-container {
//   overflow-x: inherit;
// }
// .side-navigation-menu .dx-treeview-item.dx-state-hover {
//   background-color: transparent;
//   opacity: 0.8;
// }
// .side-navigation-menu .dx-treeview-item .dx-icon {
//   position: relative;
//   position: absolute;
//   top: 13px;
//   left: 10px;
// }

// .side-navigation-menu::-webkit-scrollbar {
//   display: none;
// }

// .side-navigation-menu .dx-treeview-node {
//   color: #fff;
//   position: relative;
// }
// .side-navigation-menu .dx-treeview-toggle-item-visibility {
//   opacity: 0;
// }
// .side-navigation-menu .dx-treeview-item .dx-treeview-item-content a span {
//   overflow: hidden;
//   display: -webkit-box;
//   padding-left: 10px;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
// }
.dx-popover-wrapper .dx-popup-content {
  padding: 10px 0;
}
.action-noti {
  margin: 0;
}
.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  text-align: center !important;
}
.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  display: none;
}
.report {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}
.report th {
  background-color: #eee;
  font-weight: bold;
  white-space: normal;
  text-align: center;
}
.report td {
  white-space: nowrap;
}

.report td:nth-child(2) {
  white-space: normal;
  text-align: left;
}
.total-inventory td:nth-child(2) {
  white-space: nowrap !important;
  text-align: right;
}
.report th,
.report td {
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid;
}
.report td:last-child {
  text-align: right;
}
.report td:first-child {
  font-weight: bold;
}
.report .children td:first-child {
  padding-left: 13px;
  font-weight: normal;
}
.report td:last-child {
  white-space: nowrap;
}
.dx-popup-title .dx-toolbar-before {
  left: 0;
  top: 0;
}
.time-popup {
  text-align: right;
  position: fixed;
  right: 20px;
  bottom: 5px;
  margin: 0;
  color: #7d7c7c;
}
.wrap_selectBatches .selectBatches {
  width: 75%;
}
.wrap_selectBatches .buttonBatches {
  width: 25%;
}
.wrap_selectBatches .dx-field-label {
  width: 33%;
}
.wrap_selectBatches .dx-field-value {
  width: 67% !important;
}
.UpdateNotesForm .p-tab-lang {
  padding-top: 0px;
}
.UpdateNotesForm .tab-lang-style {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.action-top {
  position: absolute;
  right: 25px;
  z-index: 9;
  top: 10px;
}
@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
// @media screen {
//   thead {
//     display: block;
//   }
//   tfoot {
//     display: block;
//   }
// }
// @page {
//   margin-top: 2cm;
//   margin-bottom: 2cm;

//   @bottom-left {
//       content: "Footer content goes here.";
//       border-top: 1px solid #000000;
//   }
// }

// .a4In header{
//   position: fixed;
//   top: 0px;
//   height: 100px;
//   background-size: contain !important;
//   width: 100%;
// }
// .a4In footer{
//   position: fixed;
//     bottom: 0px;
//     height: 100px;
//     width: 100%;
//     background-size: contain !important;
// }
.TimesNewRoman {
  font-family: "Times New Roman", Times, serif;
}
.table-inventory {
}
.stamp {
  padding: 15px 30px 0;
}
.stamp p {
  margin: 0;
  font-size: 23px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}
.des-stamp {
  height: 310px;
}
.svg-barcode svg text {
  font-size: 27px !important;
  font-weight: bold !important;
}
.contenta4 {
  height: 100%;
  display: flex;
}
.report td {
  word-break: break-word;
}
.table-scroll thead td img:first-child {
  opacity: 0;
}
.table-scroll thead td img:last-child {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
.table-scroll thead td img,
.table-scroll tfoot td img {
  width: 100%;
  object-fit: contain;
}
.table-scroll tfoot td img:first-child {
  opacity: 0;
}
.table-scroll tfoot td img:last-child {
  position: fixed;
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
// .stamp p{
//   margin: 0;
//   font-size: 28px;
//   font-weight: bold;
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
// }
// .stamp p.des-line-2{
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }

#scheduler .dx-scheduler-cell-sizes-vertical {
  height: 30px;
}

.toasts-container .toast {
  flex-basis: unset;
}

.dx-filemanager
  .dx-filemanager-thumbnails
  .dx-filemanager-thumbnails-view-port
  .dx-filemanager-thumbnails-item
  .dx-filemanager-thumbnails-item-content
  .dx-filemanager-thumbnails-item-thumbnail {
  border: 0;
  height: 100px;
  width: 100px;
  font-size: 90px;
}
.dx-filemanager
  .dx-filemanager-thumbnails
  .dx-filemanager-thumbnails-view-port
  .dx-filemanager-thumbnails-item {
  width: 100px;
  height: unset;
}

#system-info .dx-multiview-item-container {
  padding: 10px;
}